<template>
    <div class="footer-container">
    <div>
        <div class="container footer" style="width:100%;">
        <div class="row row-footer">
            <span class="items">Annonces</span>
            <ul class="row" style="">
                <router-link
                  class="sous-items"
                  tag="li"
                  to="/acheter"
                  exact
                  >Acheter</router-link
                >
                <router-link
                  class="sous-items"
                  tag="li"
                  to="/vendre"
                  exact
                  >Vendre</router-link
                >
                <!-- <router-link
                class="sous-items"
                  tag="li"
                  to="/louer"
                  exact
                  >Louer</router-link
                > -->
              </ul>
        </div>
        <div class="row  row-footer">
            <span class="items">A propos</span>
            <ul class="">
                <router-link
                  class="sous-items"
                  tag="li"
                  to="/sommes-nous"
                  exact
                  >Qui sommes-nous ?</router-link
                >
                <router-link
                  class="sous-items"
                  tag="li"
                  to="/mention-legales"
                  exact
                  >Barème d’honoraires</router-link
                > 
                <!-- <router-link
                class="sous-items"
                  tag="li"
                  to="/partenariat"
                  exact
                  >Partenariat</router-link
                > -->
              </ul>
        </div>
        <div class="row  row-footer">
            <span class="items">Contact</span>
            <ul class="">
                <router-link
                  class="sous-items"
                  tag="li"
                  to="/nous-contacter"
                  exact
                  >Nous contacter</router-link
                >
                <router-link
                  class="sous-items"
                  tag="li"
                  to="/nousjoindre"
                  exact
                  >Nous rejoindre</router-link
                >
                <router-link
                class="sous-items"
                  tag="li"
                  to="/trouver-un-conseiller"
                  exact
                  >Trouver un conseiller</router-link
                >
              </ul>
        </div>
       <!--  <div class="row  row-footer">
            <span class="items">Services</span>
            <ul class="">
                <router-link
                  class="sous-items"
                  tag="li"
                  to="/acces-pro"
                  exact
                  >Accés pro</router-link
                >
                <router-link
                  class="sous-items"
                  tag="li"
                  to="/annonceur"
                  exact
                  >Devenir annonceur</router-link
                >
              </ul>
        </div> -->
        <div class="row  row-footer">
            <span class="items">Information légales</span>
            <ul class="">
                <router-link
                  class="sous-items"
                  tag="li"
                  to="/mentions-legales"
                  exact
                  >Mentions légales</router-link
                >
                <router-link
                  class="sous-items"
                  tag="li"
                  to="/politique-confidentialite"
                  exact
                  >Politique de confidentialité</router-link
                >
                <router-link
                class="sous-items"
                  tag="li"
                  to="/parametrer-cookies"
                  exact
                  >Paramétrer les cookies</router-link
                >
              </ul>
        </div>
        </div>

        <div class="container footer-pb">
            <div class="logo-footer col-md-9">
                <div><img class="logo" src="../../assets/img/logo-2L-immo.png"> </div>
                <span class="sous-items">Le meilleur site pour votre recherche immobilière</span>
            </div>

            <div class="logo-icons col-md-3">
                <div class="container-social"><img class="social-img" src="../../assets/img/youtube.png"> </div>
                <div class="container-social"><img class="social-img" src="../../assets/img/twitter.png"> </div>
                <div class="container-social"><img class="social-img" src="../../assets/img/instagram.png"> </div>
                <div class="container-social" ><img class="social-img" src="../../assets/img/facebook.png"> </div>
            </div>

        </div>
    
    </div>
<cookie-bar :text="textCockiee" :acceptText="'Accepter tout'"/>
    </div>
  </template>
  <script  >
    import fb from "../../assets/img/facebook.png"
    import twitter from "../../assets/img/twitter.png"
    import youtube from "../../assets/img/youtube.png"
    import logo from "../../assets/img/logo-2L-immo.png"
    import instagram from "../../assets/img/instagram.png"
import CookieBar from "vue-cookie-bar";

  export default {
components: {
   
    CookieBar
  },
    data() {
      return {
        textCockiee:'Nous utilisons des cookies pour améliorer votre expérience de navigation, diffuser des publicités ou des contenus personnalisés et analyser notre trafic. En cliquant sur « Tout accepter », vous consentez à notre utilisation des cookies.',
        fb: fb,
        twitter: twitter,
        youtube: youtube,
        logo:logo,
        instagram:instagram,
       }
    },
      mounted() {
      },
  }
  </script>
  <style scoped>
  .footer{
    padding: 25px;
    display: inline-flex;
    align-content: space-between;
  }
 .items{
    font-size: 18px;
    color: #F1F1F1;
    text-align: left;
    float: left;
    font-weight: 500;
    padding: 10px;
    display: flex;
    font-weight: bold;
 }

 .sous-items{
    font-size: 14px;
    font-weight: 200;
    color: #F1F1F1;
    list-style-type: none;
    text-align: left;
    place-content: center;
    cursor: pointer;
 }
.footer-container{
    background-color: #3D4447;
}
.logo{
    width: 19%;
}
.logo-footer{
    text-align: left;
    width: 67%;
}
.footer-container{
    padding: 25px;
}

.social-img{
    width: 25px;
    height: 25px;
}

.logo-icons {
    align-self: end;
    display: inline-flex;
    flex-direction: row-reverse;
    margin-right: 7px;
}
.container-social{
  padding: 10px 10px 10px 10px;
}
.row-footer{
    width: 100%;
}
.footer-pb{
display: flex;
width: 100%;
}



@media (max-width: 768px){
    .row-footer{
        width: 50%;
    }
    .footer-pb{
        display: block;
        text-align: -webkit-center;
    }
    .logo-footer{
        text-align: center;
    }
    .container.footer {
        display: grid;
        grid-template-columns: 50% 50%;
        justify-items: center;
    }

    .row-footer{
      flex-direction: column;
      align-items: center;
  }
}

@media (max-width: 600px){
    .container.footer {
        display: block;
        justify-items: center;
    }

    .row-footer{
        width: 100%;
        text-align-last: center;
        margin-top: 0px !important;
        margin-right: 0px !important;
        margin-left: 0px !important;
    }

    span.items {
        justify-content: center;
    }
}

  </style>
  