<template>
  <div class="content-ville">
    <div class="container container-ville">
      <div class="row">
        <div class="col-12">
          <div class="content-parent">
            <div>
              <p class="titre_ville col-12">{{title}}</p>
              <span class="sous_titre_ville col-12">
                Trouver votre bien dans toute la France
              </span>
            </div>
             <div class="btn_center">
               <b-button class="btn_all_ville" @click="goToAnnonce()">{{button}} </b-button>
            </div>
            </div>
           
           
          </div>

          <div id="tile" class="tile is-child">
            <template v-for="(img, index) in images">
              <a href="#" :key="index" class="content_ville" @click="goToAnnonceByVille(img.codePostal)">
                <img
                  class="img_size"
                  :src="require(`@/assets/png/${img.src}`)"
                />
                <span class="img_title_ville">{{ img.title }}</span>
              </a> 
             <!--  <router-link class-active="active" :to="'/details/' + data.id+'/'+data.adresse" exact>
                <img
                    class="img_size"
                    :src="require(`@/assets/png/${img.src}`)"
                  />
                  <span class="img_title_ville">{{ img.title }}</span></router-link
              > -->
            </template>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props : ['title','button','data'],
  name: "Ville",
  data() {
    return {
      backgroundImage: " background-image",
      images: [
        { src: "Bordeaux.png", title: "Bordeaux", codePostal:"33200" },
        { src: "Paris.png", title: "Paris", codePostal:"75001"},//75020
        { src: "Monaco.png", title: "Monaco", codePostal:"98000" },
        { src: "Marseille.png", title: "Marseille", codePostal:"13001" },
        { src: "Toulous.png", title: "Toulouse", codePostal:"31000" },
      ],
      timer: null,
      currentIndex: 0,
    };
  },

  mounted: function () {},

  methods: {
   
    goToAnnonce(){
      this.$router.push({ path: "/acheter" });
    },

    goToAnnonceByVille(codePostal){
      this.$router.push({
          path:"/acheter",
           query: {
             code_postal: codePostal,
           },
        });
    }
  },

  computed: {
    currentImg: function () {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    },
  },
};
</script>

<style scoped>
.tile {
  position: relative;
  display: flex;
}

.img_title_ville {
  font-weight: Bold;
  background-color: #2da9c5;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  font-size: 24px;
  display: block;
  position: relative;
  bottom: 0;
  top: -10%;
  left: 0;
  border-radius: 0x 0px 12px 12px;
  right: 0; /* voir règles */
  padding: 2px;
  overflow: hidden; /* empêche le dépassement de texte */
}

.img_size {
  /* width: 250px;
  height: 185px; */
  width: 100%;
}
.img_size {
  transition: ease 1s;
}
/* SURVOLE */
.is-child:hover .img_size {
}
.is-child:hover .img_size,
.is-child:hover {
}

/* Gestion des liens */
.is-child a:link,
.is-child a:active,
.is-child a:visited,
.is-child a:hover,
.is-child a {
  text-decoration: none;
  color: #fff;
}
.container {
  height: 430px;
  display: flex;
  align-items: center;
}
.content_ville {
  padding: 5px;
  flex: 1;
}
.sous_titre_ville {
  font-family: "Poppins";
  font-size: 18px;
  color: #adaeb8;
  text-align: left;
  position: relative;
}
.titre_ville {
  font-family: "Poppins";
  color: #646c70;
  text-align: left;
  margin-bottom: 0px !important;
  position: relative;
  font-size: 25px;
  text-align: left;
  font-weight: 400;
  text-transform: capitalize;
}
.btn_all_ville {
  background-color: #2da9c5;
  color: white;
  border-radius: 8px;
  border: 0px;
  float: right;
  position: absolute;
  right: 0.5%;
  margin-top: 15px;
  height: 35px;
  width: 250px;
}

.btn_all_ville:hover {
  background-color: darkorange;
}

.content_ville:hover {
  transform: scale(0.9);
}

.img_conseiller {
  width: 40%;
  text-align: left;
}
.conseilleul {
  display: block;
}
.content-ville {
  background-color: white;
  height: auto;
}
.content-parent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
}

@media (max-width: 768px){
.content-parent{
  display: block;
  text-align-last: center;
  position: relative;
}
.container.container-ville {
  height: auto;
}
.tile{
  display: grid;
  grid-template-columns: 50% 50%;
  position: initial;
  margin-top: 70px;
}
.titre_ville{
  text-align: center;
}
.btn_center {
    place-self: center;
    margin: auto;
    display: flex;
    justify-content: center;
    width: 100%;
}
button.btn.btn_all_ville.btn-secondary {
  position: absolute;
  float: initial !important;
  right: inherit;
  width: 100%;
}
}
</style>
