<template>
  <div class="content-slide">
    <transition-group name="fade" tag="div">
      <div v-for="i in [currentIndex]" :key="i" class="content-slide">
        <!-- hello
        <img :src="require(`@/assets/png/${currentImg}`)" /> -->
        <div
          class="slide-backroung test"
          :style="{
            backgroundImage:
              'url(' + require(`@/assets/png/${currentImg}`) + ')',
          }"
        >
          <div class="container-title-slide">
            <div class="parent-title">
              <h3 class="titre_slide">TROUVEZ VOTRE BIEN</h3>
              <div style="display: inline-grid">
                <h3 class="sous_titre_slide">IMMOBILIER</h3>
                <h6 class="desc_sous_titre_slide">
                  parmi les annonces immobilières
                </h6>
              </div>


              <div>
                <b-tabs content-class=" " v-model="tabIndex" >
                  <b-tab title="Estimer" href="#estimer" @click="redirection(0)" >
                    <p class="title-tab">Combien vaut votre bien ?</p>
                    <div
                      class="content-search-dropdown"
                      style="position: relative"
                    >
                      <div class="label_search_wizard">
                        <b-input-group>
                          <!-- <b-input-group-prepend>
                          <span class="input-group-text"><i class="fa fa-user fa-lg"></i></span>
                        </b-input-group-prepend> -->
                          <span class="icon-btn">
                            <b-form-input
                              class="LoginInput"
                              size="lg"
                              placeholder="Indiquez l'adresse du bien"
                              v-model="location"
                              @keyup="search"
                              :aria-expanded="places.length > 0 ? true : false"
                              aria-controls="places-dropdown"
                            >
                            </b-form-input
                          ></span>
                          <b-button class="btn-estimer" @click="goToEstimer()">
                            Estimer</b-button
                          >
                        </b-input-group>
                      </div>

                      <ul
                        role="menu"
                        :class="['places-dropdown']"
                        v-if="places.length > 0"
                      >
                        <li
                          role="menuitem"
                          class="place"
                          v-for="place in places"
                          :key="place.place_id"
                          @click="selectPlace(place)"
                        >
                          {{ place.description }}
                        </li>
                      </ul>
                    </div>
                  </b-tab>
                  <b-tab title="Acheter" href="#acheter" @click="redirection(1)">
                    <p class="title-tab">
                      Trouvez votre bien idéal parmi notre sélection
                    </p>

                    <div class="container-actions-recherche">
                      <div>
                        <multiselect
                          class="LoginInput width-achat type-bien-home"
                          select-Label=""
                          selected-Label=""
                          deselect-Label=""
                          v-model="achat.maison_app"
                          :options="typeBienAchat"
                          :multiple="false"
                          track-by="text"
                          label="text"
                          :close-on-select="true"
                          :show-labels="false"
                          placeholder="Type de bien"
                        >
                        </multiselect>
                      </div>

                      <div>
                        <b-dropdown
                          @hide="onDropdownHide"
                          id="dropdown-1"
                          text="Détails de bien"
                          class="content-heigth width-achat Detail-bien"
                        >
                          <b-dropdown-item
                            @click.native.capture.stop
                            class="dropdown-padding-15"
                            style="z-index: 9999999"
                          >
                            <div class="container-actions-dropdowns">
                              <div>
                                <b-form-select
                                  placeholder="Chambres"
                                  class="input_adresse content-heigth width-achat"
                                  v-model="achat.details_bien.nombre_chambre"
                                  :options="ListeNombreChambre"
                                ></b-form-select>
                              </div>

                              <div>
                                <b-form-select
                                  placeholder="Etat du bien"
                                  class="input_adresse content-heigth width-achat"
                                  v-model="achat.details_bien.etat_bien"
                                  :options="ListeEtatBien"
                                ></b-form-select>
                              </div>

                              <div>
                                <b-form-select
                                  placeholder="Pièces"
                                  class="input_adresse content-heigth width-achat"
                                  v-model="achat.details_bien.piece"
                                  :options="ListeNombrePiece"
                                ></b-form-select>
                              </div>
                            </div>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>

                      <div>
                        <b-input-group
                          class="input_adresse content-heigth width-achat adresse"
                        >
                          <b-form-input
                            class="btn-grp-border"
                            placeholder="Adresse"
                            style="border: 1px solid white; white-space: nowrap"
                          >
                          </b-form-input>
                          <b-input-group-prepend>
                            <span
                              class="input-group-text"
                              style="border: 1px solid white"
                            >
                              <b-icon
                                icon="geo-alt-fill"
                                variant="secondary"
                                class="h5"
                              ></b-icon
                            ></span>
                          </b-input-group-prepend>
                        </b-input-group>
                      </div>
                      <div>
                        <div>
                          <b-dropdown
                            @hide="onDropdownHide"
                            id="dropdown-1"
                            text="Budget"
                            class="content-heigth width-achat Detail-bien Detail-bien-last"
                          >
                            <b-dropdown-item
                              @click.native.capture.stop
                              class="dropdown-padding"
                            >
                              <div class="container-actions-dropdowns">
                                <div>
                                  <b-dropdown-item @click.native.capture.stop>
                                    <b-form-input
                                      type="number"
                                      placeholder="Budget min"
                                      v-model="achat.budget_min"
                                      class="width-budget"
                                    />
                                  </b-dropdown-item>
                                </div>

                                <div>
                                  <b-dropdown-item @click.native.capture.stop>
                                    <b-form-input
                                      type="number"
                                      placeholder="Budget max"
                                      v-model="achat.budget_max"
                                      class="width-budget"
                                    />
                                  </b-dropdown-item>
                                </div>
                              </div>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                      <div>
                        <b-button
                          style="display: flex"
                          class="btn-trouver btn-estimer"
                          @click="goToRecherche()"
                        >
                          <b-icon
                            icon="search"
                            variant="secondary"
                            class="h5 btn-icon-search"
                          ></b-icon>
                          Trouver</b-button
                        >
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
              <h6 class="titre_slide_reseau" > <strong> 1er réseau </strong> de conseillers en immobilier et expert en financement</h6>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
    <!-- <a class="prev" @click="prev" href="#">&#10094; </a>
    <a class="next" @click="next" href="#">&#10095; </a> -->
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Multiselect from "vue-multiselect";
import { mapActions } from "vuex";
export default {
  components: {
    Multiselect,
  },
  name: "Slider",
  metaInfo: function () {
    return {
      script: [
        {
          src: `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places`,
          async: true,
          defer: true,
        },
      ],
    };
  },

  data() {
    return {
      code_postale:null,
      village: null,
      rue: null,
      service: null,
      selected: {},
      places: [],
      location: "",
      tabIndex: 0,
      apiKey: "AIzaSyDx3ioOTy4Yhd1hpg2EGaMtzz66_DPyATw",
      achat: {
        maison_app: null,
        details_bien: {
          nombre_chambre: null,
          etat_bien: null,
          piece: null,
        },
        budget_min: null,
        budget_max: null,
      },
      ListeNombrePiece: [
        { value: null, text: "Chambres" },
        { text: "1 pièce", value: "1", checked: false },
        { text: "2 pièce", value: "2", checked: false },
        { text: "3 pièce", value: "3", checked: false },
        { text: "4 pièce", value: "4", checked: false },
        { text: "5 pièce ", value: "5", checked: false },
        { text: "6 pièce et plus", value: "6", checked: false },
      ],

      ListeEtatBien: [
        { value: null, text: "Etat du bien" },
        { text: "Ancien", value: "1", checked: false },
        { text: "Neuf", value: "2", checked: false },
        { text: "Viager", value: "3", checked: false },
        { text: "Projet de construction", value: "4", checked: false },
      ],
      ListeNombreChambre: [
        { value: null, text: "Pièces" },
        { text: "1 chambre", value: "1", checked: false },
        { text: "2 chambre", value: "2", checked: false },
        { text: "3 chambre", value: "3", checked: false },
        { text: "4 chambre", value: "4", checked: false },
        { text: "5 chambre ", value: "5", checked: false },
        { text: "6 chambre et plus", value: "6", checked: false },
      ],
      typeBienAchat: [
        { text: "Appartement", value: "1", checked: false },
        { text: "Maison", value: "2", checked: false },
        { text: "Terrain", value: "3", checked: false },
        { text: "Parking / Box", value: "4", checked: false },
        { text: "Loft / Atelier ", value: "5", checked: false },
        { text: "Bâtiment", value: "6", checked: false },
      ],
      backgroundImage: " background-image",
      images: ["slide-2.png", "slide-1.png"],
      timer: null,
      currentIndex: 0,
    };
  },

  mounted: function () {
    localStorage.clear();
    this.startSlide();
  },

  methods: {
    ...mapActions(["getPlace"]),
    goToRecherche() {
      this.$router.push({ path: "/acheter" });
    },
    goToEstimer() {
      this.$router.push({ path: "/estimer" });
    },
    startSlide: function () {
      //  this.timer = setInterval(this.next, 4000);
    },

    next: function () {
      this.currentIndex += 1;
    },
    prev: function () {
      this.currentIndex -= 1;
    },

    MapsInit: function () {
      this.service = new google.maps.places.AutocompleteService();
      console.log("aaa", this.service);
    },
    search: async function () {
      try {
        const results = await this.service.getPlacePredictions({
          input: this.location,
          fields: ["address_components", "icon"],
          types: ["address"],
          componentRestrictions: {
            country: ["fr"],
          },
        });

        this.places = results.predictions;
      } catch (error) {
        console.log(error);
      }
    },

    selectPlace: function (place) {
      this.rue = place.terms[0].value;
      this.village = place.terms[1].value;
      this.location = place.description;
      this.selected.description = place.description;
      this.selected.location_id = place.place_id;
      this.places = [];

      let vm=this
    
      this.getPlace({ id: this.selected.location_id }).then((response)=>{
          response.forEach(element => {
         
              if(element.types[0]=='postal_code'){
                
                vm.code_postale=element.short_name
                localStorage.setItem("code_postale", vm.code_postale);
              }
          });
      });
   
      localStorage.setItem("village", this.village);
      localStorage.setItem("rue", this.rue);
      localStorage.setItem("location", this.location);
    },
    clearForm: function () {
      this.location = "";
      this.selected = {};
      this.places = [];
      this.$emit("selectedArea", {});
    },

    redirection(index){
 
        if (index == 0) {
        
         if( screen.width <= 768 ) {
          this.goToEstimer()
         }
      } else {
        
        if( screen.width <= 768 ) {
          this.goToRecherche()
        }
      }
    }
  },

  computed: {
    currentImg: function () {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    },
  },
  watch: {
    tabIndex: function () {
      if (this.tabIndex == 0) {
        this.currentIndex = 0;
         
      } else {
        this.currentIndex = 1;
        
      }
    },
    location: function () {
      if (this.service === null) {
        this.MapsInit();
      }
      if (this.location.length === 0) {
        this.clearForm();
      }
    },
  },
};
</script>

<style>

.dropdown-toggle::after{
  border-top: 6px solid #999 !important;
}

span.icon-btn {
  width: 84%;
  margin-right: 5px;
  position: relative;
}

.multiselect.LoginInput.width-achat.type-bien-home .multiselect__tags {
  padding: 3px 40px 6px 8px !important;}

span.icon-btn::after {
  content: "";
  background-image: url("@/assets/img/icon-location.png");
  height: 24px;
  width: 18px;
  position: absolute;
  left: 3rem;
  top: 12px;
}

.adresse .btn-grp-border.form-control::placeholder {
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: left !important;
}
.adresse .input-group-text {
  border-radius: 0px !important;
}

.adresse .form-control {
  border-radius: 0px !important;
}
.adresse .input-group-prepend {
  border-radius: 0px;
}

.Detail-bien.Detail-bien-last.btn-group > .btn.dropdown-toggle.btn-secondary {
  border-radius: 0rem 0.5rem 0.5rem 0rem !important;
  height: 42px;
}
.btn-grp-border.form-control::placeholder {
  border-radius: 0px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: left !important;
}
.input-group.input_adresse.content-heigth.width-achat.adresse {
  border-radius: 0px !important;
}

.information_bien {
  display: flex !important;
}
option:hover {
  background-color: red;
}
.multiselect__content {
  z-index: 99999;
  display: block;
}
.dropdown-toggle {
  background-color: white !important;
  color: #646c70 !important;
  border: 1px solid white;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #ced4da !important;
  height: 43px;
}
.dropdown-toggle {
  background-color: white !important;
  color: #646c70 !important;
  border: 1px solid white;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #ced4da !important;
  height: 43px;
}
.input_adresse::placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
  text-align: left !important;
}
.width-achat {
  border: 1px solid transparent !important;
}

.Detail-bien.btn-group > .btn.dropdown-toggle.btn-secondary {
  border: 0px !important;
  border-radius: 0px;
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: left !important;
  position: relative;
}

.Detail-bien .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 6px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
  border-bottom: 0;
  position: absolute;
  right: 9px;
  bottom: 19px;
}
.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 7px 40px 6px 8px !important;
  margin-right: 2px;
  border-radius: 5px;
  border: 1px solid #cfd4d942 !important;
  background: #fff;
  font-size: 14px;
  border-radius: 0.5rem 0rem 0rem 0.5rem;
}
.multiselect.LoginInput.width-achat.type-bien-home .multiselect__tags .multiselect__placeholder {
  font-weight: 400 !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}
</style>
<style scoped>
.label_search_wizard {
  padding: 0px 25px 25px 25px;
  color: #646c70 !important;
  border: 1px solid transparent;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold !important;

  width: 100%;
}
.places-dropdown {
  position: absolute !important;
  background-color: white;
  z-index: 999999;
  top: 56px;
  list-style-type: none;
  border: 1px solid #ced4da;
  left: 0;
  border-radius: 8px;
  left: 25px;
  right: 25px;
  margin: auto;
}
.place {
  text-align: left;
  border-bottom: 1px solid #0000002b;

  padding: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  color: black;
}
.place:hover {
  color: white;
  background-color: #2da9c5;
  text-align: left;
  border-bottom: 1px solid #0000002b;
  height: 35px;
  border-radius: 5px;
}

.width-achat {
  width: 100%;
}
.dropdown-padding-15 {
  padding: 15px;
  background-color: #f5f8fc !important;
}
.dropdown-padding {
  padding: 15px;
  background-color: #f5f8fc !important;
}
.btn-icon-search {
  color: white !important;
  margin-right: 7px;
  /* margin-top: 2px; */
  padding-top: 3px;
}
.width-achat .multiselect__tags {
  border-radius: 0.5rem 0rem 0rem 0.5rem !important;
}
.width-budget {
  border: 1px solid #ced4da !important;
  width: 100%;
  display: flex;
}
.width-budget::placeholder {
  color: #646c70 !important;
  border: 1px solid white;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold !important;
}
.btn-grp-border::placeholder {
  color: #646c70 !important;
  border: 1px solid white;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold !important;
}

.input_adresse {
  border: 1px solid white;
}
.input-group-text {
  white-space: nowrap;
  background-color: white !important;
  border-left: 0px !important;
  border-radius: 0px 4px 4px 0px;
  padding: 10px 10px 2px 5px;
  white-space: nowrap;
}
.input_adresse:focus {
  border: 1px solid white;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

.container-actions-dropdowns {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  background-color: #f5f8fc !important;
}
.container-actions-recherche {
  display: grid;
  grid-template-columns: 21% 21% 21% 21% 16%;
  justify-content: start;
  align-items: center;
  padding-right: 0%;
  background-color: #f5f8fc4d;
  border-radius: 0.5rem;
  padding: 0px 0px 25px 0px;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.9s ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width: 100%;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width: 100%;
  opacity: 0;
}

img {
  height: 600px;
  width: 100%;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50vh;
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
}

.next {
  right: 0;
}

.prev {
  left: 0;
  margin-left: 5px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.9);
}
.test {
  font-size: 20px;
  text-align: center;
  color: red;
  height: 735px;
}
.content-slide {
  min-height: 100vh;
  max-height: 100vh;
}
.slide-backroung {
  background-size: 100%;
  background-repeat: round;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
}
.slide-backroung::before {
  background-color: red;
}
.titre_slide_reseau {
  color: white;
  font-family: "Poppins";
  margin-bottom: 0 !important;
  font-size: 20px;
  font-weight: 300;
      margin-top: 7%;
    margin-left: 10%;
    font-size: 29px;
}
.titre_slide {
  color: white;
  font-family: "Poppins";
  margin-bottom: 0 !important;
  font-size: 2vw;
  font-weight: 300;
}
.sous_titre_slide {
  color: white;
  font-family: "Poppins";
  font-weight: 600 !important;
  font-size: 5.2vw;
  margin-bottom: -10px;
  margin-top: -10px;
}
.container-title-slide {
  align-items: center;
  height: 100vh;
  background-color: #24559021;
  display: flex;
  width: 100%;
  position: absolute;
}
.desc_sous_titre_slide {
  color: white;
  font-family: "Poppins";
  font-size: 1.3vw;
  font-weight: Thin;
  text-align: right;
  margin-bottom: 6vh;
  font-weight: 200;
}
.parent-title {
  text-align: left;
  padding-left: 15%;
  position: relative;
  width: 100%;
}
.title-tab {
  font-size: 16px;
  color: #4b4b4b;
  font-weight: 600;
  padding: 0px 2.5vw;
  margin-bottom: 7px !important;
  margin-top: -3px !important;
}
.btn-estimer {
  background-color: #2da9c5;
  color: white;
  border-radius: 0.5rem !important;
  border: 0px solid white;
  width: 15%;
}
.btn-estimer:hover {
  background-color: darkorange;
  color: white;
  border-radius: 5px;
  border: 0px solid white;
}

@media (max-width: 768px) {
  .content-slide {
    min-height: 70vh;
    max-height: 70vh;
  }
  .container-title-slide {
    align-items: center;
    height: 70vh;
    display: flex;
    width: 100%;
    position: absolute;
  }
  .slide-backroung {
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 70vh;
    max-height: 70vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
</style>

<style>
.dropdown-padding {
  width: 29vw !important;
}

.container-actions-recherche .multiselect__tags {
  border-radius: 0.5rem 0px 0px 0.5rem !important;
}
.Detail-bien .content-heigth {
  width: 15vw;
}

.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}
.parent-title li.nav-item {
  margin-right: 2px;
}
.dropdown-toggle {
  background-color: white !important;
  color: #646c70;
  border: 1px solid white;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold !important;
}
.dropdown-toggle:hover {
  background-color: white !important;
  color: #646c70;
  border: 1px solid white;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold !important;
}
.multiselect__placeholder {
  color: #646c70 !important;
  font-family: "Poppins";
  font-size: 14px;
  white-space: nowrap;
}

.content-heigth {
  height: 44px;
  width: 250px;
  border: 1px solid #cfd4d942 !important;
  border-radius: 7px;
  color: #646c70 !important;
  font-weight: bold;
  font-family: "Poppins";
}
.dropdown-toggle {
  background-color: white !important;
  color: #646c70;
  border: 1px solid white;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold;
}
.dropdown-toggle:hover {
  background-color: white !important;
  color: #646c70;
  border: 1px solid white;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: darkorange !important;
  color: white !important;
}
.nav-tabs .nav-link {
  background-color: #2da9c5 !important;
  color: white !important;
  border-color: none !important;
  border: 0px solid white !important;
  width: 150px;
  text-align: center;
}
.tab-content {
  background-color: #f5f8fccc;
  padding: 14px 19px 0px 15px;
  width: 78% !important;
  border-radius: 0px 8px 8px 8px;
}
.nav-tabs {
  --bs-nav-tabs-border-width: 0px !important;
}
.LoginInput.form-control.form-control-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-radius: 0.5rem !important;
  margin-right: 5px;
  border: 1px solid transparent !important;
  padding: 0.5vw 5rem;
}
button.btn.btn-trouver.btn-secondary {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  padding: 9px 8px 5px 5px;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-left: 5px;
}

@media (max-width: 768px) {
 

 .titre_slide_reseau {
    font-size: 5vw !important;
        margin-top: 0px !important;
    margin-left: 10%;
    
  }
  .sous_titre_slide {
    font-size: 12vw !important;
  }

  .titre_slide {
    font-size: 7vw !important;
  }

  .container-title-slide .tab-content {
    display: none;
  }
  .container-title-slide .nav-tabs {
    display: flex;
    flex-direction: column;
  }
  .container-title-slide .nav-tabs .nav-link {
    border-radius: 0.5rem !important;
    width: 100% !important;
  }

  .container-title-slide .parent-title li.nav-item {
    margin-right: 0px;
  }
  .container-title-slide .parent-title li.nav-item {
    margin: 2px;
  }

  .desc_sous_titre_slide {
    text-align: center !important;
    font-size: 4.3vw !important;
    font-weight: 200;
    margin-bottom: 25px !important;
  }

  .content-slide{
    min-height: 70vh ;
    max-height: 70vh ;
  }

  .tab-content {
    padding: 7px 8px 18px 7px;
  }
  .slide-backroung {
    position: relative;
  }

  .slide-backroung.test .parent-title {
    left: 0% !important;
    text-align: center !important;
    padding-left: 15vw !important;
    padding-right: 15vw !important;
    position: absolute !important;
    width: 100%;
  }



  .slide-backroung.test {
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 70vh !important;
    max-height: 70vh !important;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .test {
    height: 450px !important;
  }


  .slide-backroung.test .container-title-slide{
    align-items: center;
    height: 70vh;
    display: flex;
    width: 100%;
    position: absolute;
  }

  .prev,
  .next{
    top: 35vh;
  }

  .tab-content {
    width: 100% !important;
    border-radius: 8px 8px 8px 8px;
  }
  .nav-tabs {
    justify-content: center;
  }
}
</style>
