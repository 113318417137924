<template>
    <div  >
        <div class="card" style="width: 100%;">
            
            <router-link class-active="active" :to="'/details/' + data.id+'/'+data.types" exact>
              <img class="card-img-top" :src="data.src" alt="Card image cap"/></router-link
            >
            <div class="card-body row" style="100%">
              <h5 class="card-title">{{data.titre}}</h5>
              <p class="card-text ville-type">{{data.titre_prog}}</p>
              <div class="btn-grp heart-button" style="width: 100%;">
                <p class="card-text card-price">{{Intl.NumberFormat().format(data.prix)}} €</p>
                <!-- <img class="fav-btn" src="../../assets/img/list-fav2.png">  -->
                <!-- <button class="fav-btn" type="submit"></button> -->
              </div>
            </div>
          </div>
   </div>
 </template>
 <script>
 
 import imageCard from "../../assets/img/card1.png"
 import imgbtnclicked from "../../assets/img/list-fav1.png"
 import imgbtn from "../../assets/img/list-fav2.png"
 
 export default {
   name: "CardVendre",
   props : ['data'],
   data() {
    return {
        imageCard: imageCard,
        imgbtnclicked: imgbtnclicked,
        imgbtn: imgbtn,
            }
   },
 
   mounted: function() {
   },
 
   methods: {
   },
 
   computed: {
   }
 };
 </script>
 <style scoped>
/*  p.card-text.ville-type {
  white-space: nowrap;
  overflow: auto;
  width: 18vw;
}

::-webkit-scrollbar {
  background-color:transparent !important;
  border:transparent !important;
  color:transparent !important;
  width:0px;
} */

 .btn-grp.heart-button{
  position: relative;
 }
 .btn-grp.heart-button:hover::before{
  content: "";
  width: 27px;
  height: 24px;
  background-image: url("@/assets/img/favorite-filled-jaune.png");
  right: 1%;
  top:0px;
  position: absolute;
 }

 .btn-grp.heart-button::before{
  content: "";
  width: 28px;
  height: 25px;
  background-image: url("@/assets/img/favorite-filled.png");
  right: 1%;
  top:0px;
  position: absolute;
 }

 
 .card-img-top{
    border-radius: 8px;
    padding: 5px;
    height: 19vh;
    width: 100%;
 }
 .card{
    border-radius: 10px;
    padding:1%;
    align-items: flex-start;
 }
 .card-title{
    font-family: poppins;
    color: #707070;
    font-weight: 300;
    font-size: 18px;
    float: left;
 }
 .card-price{
    font-family: poppins;
    color: #2DA9C5;
    font-weight: 500;
    font-size: 16px;
    padding: 0px 9px;
    float: left;
 }
 .ville-type{
    font-family: poppins;
    color: #ADAEB8;
    font-weight: 300;
    font-size: 14px;
 }
.fav-btn {
    float: right;
    height:25px;
    width: 25px;
    border: transparent !important;
    background-color: transparent;
}
button.fav-btn:hover {
    background-image: url('../../assets/img/list-fav1.png');
    height: 48px;
    width: 54px;
}
button.fav-btn:active {
    background-image: url('../../assets/img/list-fav1.png');
    height: 48px;
    width: 54px;
}
.card-body.row {
    text-align: left;
}

 </style>



 