import Vue from 'vue'
import Vuex from 'vuex'
// import estimation from '../store/modules/Estimations';
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {

    getPlace({commit},{id}){
      return new Promise((resolve, reject) => {
        axios
        
          .get('https://ocr.2l-courtage.fr/api/google-coords/?place_id='+id , {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
              "Connection" : "Keep-alive"
            },
           
          })
          .then((response) => {
        
            let data=response.data.data.result.address_components
            let cp=null
            
           
            resolve(data);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },
    getEstimation({commit},{data}){
      return new Promise((resolve, reject) => {
        axios
          .get('https://ocr.2l-courtage.fr/api/real-time-est/?' , {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
              "Connection" : "Keep-alive"
             
            },
            params: data
          })
          .then((response) => {
           
            resolve(response);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },
    getListeBien({commit},{page,per_page,url,skip}){
      return new Promise((resolve, reject) => {
        axios
      
         .get('https://immo-wiin.com/search-bien-neufs?&perPage='+per_page+'&limit=10&page='+page+'&skip='+skip+""+url , {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
             
            },
            
          })
          .then((response) => {
           
            resolve(response);
          })
          .catch((e) => {
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },
    getListeBienAncien({commit},{page,per_page,url,skip}){
      return new Promise((resolve, reject) => {
        axios
         .get('https://apiv1.2l-courtage.com/api/vente-bien?&per_page='+per_page+'&limit=10&page='+page+'&skip='+skip+""+url , {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
            },
            
          })
          .then((response) => {
            resolve(response);
            console.log('response',response)
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    geDetailsBienAncien({commit},{id}){
      return new Promise((resolve, reject) => {
        axios
          .get('https://apiv1.2l-courtage.com/api/vente-biens/'+id , {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
            },
            
          })
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },
    geDetailsBienNeuf({commit},{id}){
      return new Promise((resolve, reject) => {
        axios
          .get('https://immo-wiin.com/api/bien-neuf-prices?bien_id='+id , {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
             
            },
            
          })
          .then((response) => {
           
            resolve(response);
          })
          .catch((e) => {
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },
    sendemail({commit},{data}){
      return new Promise((resolve, reject) => {
        axios
          .post('https://immo-wiin.com/send-email' ,data, {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },

    sendemailContact({commit},{data}){
      return new Promise((resolve, reject) => {
        axios
          .post('https://immo-wiin.com/send-email-contact' ,data, {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },
   
    sendemailjoindre({commit},{data}){
      return new Promise((resolve, reject) => {
        axios
          .post('https://immo-wiin.com/send-email-conseiller' ,data, {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },
    getListeBienAvendre({commit}){
      return new Promise((resolve, reject) => {
        axios
         .get('https://immo-wiin.com/search-bien-neufs?&perPage=5' , {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
             
            },
            
          })
          .then((response) => {
           
            resolve(response);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },
    getListeConseilleur({commit}){
      return new Promise((resolve, reject) => {
        axios
         .get('https://apiv1.2l-courtage.com/api/all-conseiller-bien' , {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
             
            },
            
          })
          .then((response) => {
           
            resolve(response);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },
    getListeConseilleurAd({commit},{code_postal}){
      return new Promise((resolve, reject) => {
        axios
         .get('https://apiv1.2l-courtage.com/api/search-conseiller-bien?code_postal='+code_postal, {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
            },
            
          })
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },

    getListeConseilleurAdNom({commit},{code_postal,nom}){
      return new Promise((resolve, reject) => {
        axios
         .get('https://apiv1.2l-courtage.com/api/search-conseiller-bien?code_postal='+code_postal+'&nom='+nom, {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
            },
            
          })
          .then((response) => {
            resolve(response);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    getconseilleurbyId({commit},{id}){
      return new Promise((resolve, reject) => {
        axios
         .get('https://apiv1.2l-courtage.com/api/conseiller-bien/'+id , {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
             
            },
            
          })
          .then((response) => {
           
            resolve(response);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
            //Vue.$notify('error filled', 'Erreur', e, { duration: 3000, permanent: false });
          });
      });
    },

  },
  modules: {
    
  }
})
