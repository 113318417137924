import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Acheter from '../views/Acheter.vue'
import Estimer from '../views/Estimer.vue'
import Vendre from '../views/Vendre.vue'
import Contact from '../views/TrouverConseiller.vue'
 

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/trouver-un-conseiller',
    name: 'trouverconseiller',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TrouverConseiller.vue')
  },
  {
    path: '/nousjoindre',
    name: 'nousjoindre',
    component: () => import( '../views/Nous_joindre.vue')
  },
  {
    path: '/nous-contacter',
    name: 'nouscontacter',
    component: () => import( '../views/Contact.vue')
  },
  {
    path: '/mentions-legales',
    name: 'MentionsLegales',
    component: () => import( '../views/MentionsLegales.vue')
  },
  {
    path: '/politique-confidentialite',
    name: 'politiqueConfidentialite',
    component: () => import( '../views/PolitiqueConfidentialite.vue')
  },
  {
    path: '/parametrer-cookies',
    name: 'ParametrerCookies',
    component: () => import( '../views/ParametrerCookies.vue')
  },
  {
    path: '/nous-contacter',
    name: 'nouscontacter',
    component: () => import( '../views/Contact.vue')
  },
  {
    path: '/sommes-nous',
    name: '/sommes-nous',
    component: () => import( '../views/QuiSommesNous.vue')
  },
  {
    path: '/vendre',
    name: 'vendre',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Vendre.vue')
  },

  {
    path: '/estimer',
    name: 'estimer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Estimer.vue')
  },

  {
    path: '/louer',
    name: 'louer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Louer.vue')
  },

  {
    path: '/acheter',
    name: 'acheter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Acheter.vue'),
    
  },
  {
    path: '/details/:id/:type',
    name: 'details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DetailsAcheter.vue'),
   
  },

  {
    path: '/conseiller-immobilier/:id/:user',
    name: 'profile',
    component: () => import('../views/Profile.vue'),
   
  },

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
