<template>
  <div class="content-conseiller" style="background-color: #F5F8FC;">
    <div class="container  container-conseiller">
      <div class="row row-conseiller">
        <div class="col-md-12">
          <div class="content-parent-conseiller col-md-12" >
            <div class="col-md-6 col-xs-12">
              <img class="img-conseiller-home" src="@/assets/png/img.png" />
            </div>

            <div class="col-md-6 col-xs-12">
              <div class="content-details-conseiller">
                <p class="title_conseiller">
                  Comment pouvons-nous vous aider ?
                </p>
                <p class="paragraphe_conseiller">
                  2L Immo est le seul réseau immobilier ayant la double compétence :
                  <ul>
                  <li>de conseiller immobilier </li>	
                  <li>d’expert en financement </li>	
                  </ul>
                  La synergie de ces deux compétences est la clé de la réussite car : 
                  <ul>
                    <li>1 compromis sur 2 ne va pas au bout à cause du financement, </li>	
                    <li>75% de refus de prêt lorsque le client passe de particulier à particulier. </li>	
                    </ul>
                  Nous avons allié ces deux métiers pour répondre encore mieux à vos attentes et exigences.

                </p>
                <p style="text-align:center">
                  

                  <router-link class-active="active" :to="'/trouver-un-conseiller'" exact>
                    <b-button class="btn-conseiller"
                    >Trouver un conseiller
                  </b-button></router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Ville",
  data() {
    return {
      backgroundImage: " background-image",
      images: [
        { src: "Bordeaux.png", title: "Bordeaux" },
        { src: "Paris.png", title: "Paris" },
        { src: "Monaco.png", title: "Monaco" },
        { src: "Marseille.png", title: "Marseille" },
        { src: "Toulous.png", title: "Toulouse" },
      ],
      timer: null,
      currentIndex: 0,
    };
  },

  mounted: function () {},

  methods: {},

  computed: {
    currentImg: function () {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    },
  },
};
</script>

<style scoped>
.btn:hover {
  border-color: darkorange !important;
}
.content-parent-conseiller {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-conseiller {
  height: 430px;
  display: flex;
  align-items: center;
}
.content-conseiller {
  height: auto;
  padding: 25px;
}
.img-conseiller-home {
  width: 100%;
}

.title_conseiller {
  color: #646c70;
  font-size: 25px;
  font-weight: 600;
}
.paragraphe_conseiller {
  /* text-align: left;
    width: 440px; */
  font-size: 17px;

  /* font-size: 23px;
    width: 400px;
        width: 400px;
   
    margin: 0 auto;
    padding: 10px;
    text-align: left; */
}
.btn-conseiller {
  background-color: white;
  color: #2da9c5;
  border-radius: 8px;
  border: 0px;
  width: 250px;
  border: 1px solid #2da9c5;
  background-color: transparent;
}

.btn-conseiller:hover {
  background-color: darkorange;
}
.content-details-conseiller {
  text-align: left;
  margin-left: 114px;
  max-width: 896px;
}

@media (min-width: 992px) and (max-width: 1399px){
  .title_conseiller{
    font-size: 2vw;
}

.paragraphe_conseiller{
  font-size: 1vw;
}
}

@media (min-width: 769px) and (max-width: 991px){
  .title_conseiller{
    font-size: 2vw;
}

.paragraphe_conseiller{
  font-size: 13px;
}
}





@media (max-width: 768px){
  .row-conseiller{
    display: block;
  }
  span.ss_sous_titre_stat_paragh{
    justify-content: center;
}

  .content-parent-conseiller {
    display: block;
}
.container-conseiller{
  height: auto;
  padding: 10px;
}
.content-details-conseiller {
  text-align: center;
  margin-left: 0px;
  max-width: 100%;
  padding: 10px;
}
button.btn.btn-conseiller.btn-secondary {
  width: 100%;
}
}
</style>
