<template>
    <div class="footer-license d-flex">
    <div class="container">
        <span class="license">© 2023 immowiin - Tous droits réservés</span>
    </div>
    </div>
  </template>
  <script>

  export default {
    data() {
      return { }
    },
      mounted() {
      },
  }
  </script>
  <style scoped>
  .license{
    font-size: 14px;
    font-weight: 200;
    text-align: right;
    place-content: right;
    float: right;
    color: #F1F1F1;
    padding: 7px;
 }
 .footer-license{
    background-color:#373C3E; 
    width:100%;
 }


 @media (max-width: 768px){
    .license{
        font-size: 11px;
        float: initial;
        text-align: -webkit-center;
    }
}

  </style>
  