<template>
  <header class="header">
    <!-- Logo -->
    <div class="">
      <div class="white content_container">
        <div class="content_logo">
          <router-link
            class-active="active"
            class="logo_immo left"
            to="/"
            exact
          >
            <img
              src="https://immo-wiin.com/assets/img/logo-2L-immo.jpg"
              alt
              height="50"
            />
          </router-link>
          <input class="side-menu" type="checkbox" id="side-menu" />
          <label class="hamb" for="side-menu"
            ><span class="hamb-line" id="hamb-line"></span
          ></label>
          <nav class="nav" id="navbar-collapse">
            <ul class="menu">
              <router-link  @click.native="closeMenu()"

                class="content_title_header"
                tag="li"
                class-active="active"
                to="/"
                exact 
                >Accueil</router-link
              >
              <router-link  @click.native="closeMenu()"
                class="content_title_header"
                tag="li"
                class-active="active"
                to="/acheter"
                exact
                >Acheter</router-link
              >
              <router-link @click.native="closeMenu()"
                class="content_title_header"
                tag="li"
                class-active="active"
                to="/vendre"
                exact
                >Vendre</router-link
              >

              <router-link @click.native="closeMenu()"
                class="content_title_header"
                tag="li"
                class-active="active"
                to="/estimer"
                exact
                >Estimer</router-link
              >

              <router-link @click.native="closeMenu()"
                class="content_title_header"
                tag="li"
                class-active="active"
                to="/trouver-un-conseiller"
                exact
                >Trouver un conseiller</router-link
              >
            </ul>
          </nav>
          <div class="content_contact">
            <!-- <input type="button" value="Devenir conseiller immobilier" class="btn_contact" /> -->

             <router-link
                  class="btn_contact "
                  tag="li"
                  to="/nousjoindre"
                  exact
                  >Devenir conseiller immobilier</router-link
                >
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import TopNav from "@/components/NavBar/TopNav.vue"; // @ is an alias to /src
import $ from 'jquery'


export default {
  components: { TopNav },

  data() {
    return {
    };
  },
  mounted() {},
  methods: {
    getFoo() {
    $( "div.foo" ).html();
    },
    goToAcceuil() {
      this.$router.push({ name: "/" });
    },
    closeMenu() {
      $('#hamb-line').click();
      //$('#navbar-collapse').toggle();
      //console.log('hiiiiiiiiiiiiii');
    },

  },
  mounted() {},
};
</script>

<style>
nav {
  z-index: 10000;
  position: fixed;
  top: 0px;
  width: 100%;
 }
.container-actions-acheter.gridgrid .content-heigth {
  border: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-radius: 0px !important;
}

.container-actions-acheter.gridgrid .content-heigth .multiselect__placeholder {
  padding-left: 1vw !important;
  font-weight: 400 !important;
}
.container-actions-acheter.gridgrid .content-heigth  .multiselect__tags{
  border-color: #ffffff !important;
  text-align: left !important;
  padding-left: 1vw !important;
  font-weight: 400 !important;
  font-size: 14px !important;


  padding: 5px 40px 0 0px !important;
  border-radius: 0px !important;
  margin-top: 2px !important;

}
.dropdown-style button#dropdown-1__BV_toggle_{
  border-color: transparent !important;
  text-align: left !important;
  padding-left: 1vw !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  position: relative !important;;
}

.dropdown-style button#dropdown-1__BV_toggle_ .multiselect__input{
  border-color: #ffffff !important;
  text-align: left !important;
  padding-left: 1vw !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  position: relative !important;;

}

.dropdown-toggle::after {
  position: absolute !important;
  right: 13px !important;
  top: 18px !important;
}
</style>

<style scoped>

/* Theming */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
.content_title_header:active {
  color: darkorange !important;
}
nav li:hover,
nav li.router-link-active,
nav li.router-link-exact-active {
  color: darkorange;
}
.header {
  box-shadow: 1px 1px 0px 0px #80808066 !important;
}
:root {
  --white: #f9f9f9;
  --black: #36383f;
  --grey: #85888c;
}

/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: white;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

/* Header */
.header {
  background-color: white;
  box-shadow: 1px 1px 5px 0px grey;
  top: 0;
  width: 100%;
}

/* Logo */
.logo {
  display: inline-block;
  color: white;
  font-size: 60px;
  margin-left: 10px;
}

/* Nav menu */
.nav {
  width: 97%;
  height: 100%;
  position: fixed;
  background-color: darkorange;
  overflow: hidden;
}
.nav {
  padding: 0px !important;
}

.menu {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu a {
  display: block;
  padding-left: 30px;
  color: var(--white);
}

.menu a:hover {
  background-color: #22abc5;
}

.nav {
  max-height: 0;
  transition: max-height 0.5s ease-out;
}

/* Menu Icon */

.hamb {
  cursor: pointer;
  float: right;
  padding: 27px 20px;
}

.hamb-line {
  background: #22abc5;
  display: block;
  height: 2px;
  position: relative;
  width: 24px;
}

.hamb-line::before,
.hamb-line::after {
  background: #22abc5;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.hamb-line::before {
  top: 5px;
}

.hamb-line::after {
  top: -5px;
}

.side-menu {
  display: none;
}

/* Toggle menu icon */

.side-menu:checked ~ nav {
  max-height: 100%;
}

.side-menu:checked ~ .hamb .hamb-line {
  background: transparent;
}

.side-menu:checked ~ .hamb .hamb-line::before {
  transform: rotate(-45deg);
  top: 0;
}

.side-menu:checked ~ .hamb .hamb-line::after {
  transform: rotate(45deg);
  top: 0;
}

/* Responsiveness */

@media (min-width: 769px) {
  .nav {
    max-height: none;
    top: 0;
    position: relative;
    width: fit-content;
    background-color: transparent;
  }

  .menu li {
    float: left;
  }

  .menu a:hover {
    background-color: transparent;
    color: var(--grey);
  }

  .hamb {
    display: none;
  }
  .left {
    float: left;
  }

  .right {
    float: right;
  }
  .white {
    background-color: white;
  }
  .logo_immo {
    margin-left: 5%;
    height: 50px !important;
  }
  .content_logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .content_title_header {
    color: #7b7f83;
    padding: 13px 0vw 13px 3vw;
    text-decoration: none;
  }

  .content_contact {
    margin-right: 5%;
  }

  .content_container {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
  }

  .btn_contact {
    background-color: #22abc5;
    height: 39px;
    border-radius: 10px;
    color: white !important;
    border: none;
    border-radius: 8px;
    border: 0px;
    width: auto;
    height: 35px;
    padding: 5px 10px 5px 10px;
    list-style-type: none;
  }

  .btn_contact:hover {
    background-color: darkorange;
  }
}
@media (max-width: 768px) {

  nav {
    z-index: 10000;
    position: fixed;
    top: 0px;
    width: 100%;
}
.side-menu:checked ~ nav{
  margin-top: 50px;
}
   nav.nav{
    z-index: 1000000000000000000000 !important;
   }
  
  .btn_contact {
    display: none !important;
    background-color: #22abc5;
    width: 167px;
    height: 39px;
    border-radius: 10px;
    color: white;
    border: none;
  }
  nav {
    float: right !important;
    height: 100% !important;
    width: 150px !important;
    background-color: #2da9c5 !important;
  }
  nav > ul > li {
    padding: 13px 10px 13px 10px;
    text-decoration: none;
    color: #efefef;
  }
  .menu {
    height: 60px;
    display: block !important;
  }

 
}
</style>
