<template>
  <div class="content-ville  " :style="{ 'background-color': color }">
  
    <div class="container" style="padding: 25px 10px 14px 10px;">
      <div class="row">
        <div class="col-12">
          <div class="content-parent" v-if="showbtn==true">
            <div class="bloc-title">
              <div style="text-align: left;">
                <p class="titre_vendre col-12">Nos annonces immobilières</p>
                <span class="sous_titre_ville col-12">
                   prés de chez vous
                </span>
              </div>
              
            </div>
            <div class="btn_center">
                <b-button class="btn_all_ville" @click="goToAnnonce()">Simulation de prêt immobilier</b-button>
              </div>
          </div>
          <div class="content-parent" v-if="showbtn==true">
            <div>
              <p class="second_titre_vendre col-12" style="  color: #2da9c5;">A VENDRE</p>
              <span class="sous_description col-12">
                2L Immo vous accompagne dans la recherche de votre projet immobilier.
              </span>
            </div>
            <div class="content-btn-vendre">
                  <div class="multiselect">
                    <multiselect
                      class="LoginInput width-achat txt-drop"
                      select-Label=""
                      selected-Label=""
                      deselect-Label=""
                      :options="optionsRecent"
                      :multiple="false"
                      track-by="text"
                      label="text"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="Recent"
                    >
                     
                    </multiselect>
                  </div>

                  <button class="btn-voirplus btn_all_vendre" type="submit" @click="goToAnnonce()"> Voir plus  </button>
            </div>
          </div>
          

          <div id="tile" class="tile is-child">
            <template v-for="(img, index) in ListeCardAvendre">
              <a href="#" :key="index" class="content_ville">
                <CardAvendre :data="img" />
              </a>
            </template>
          </div>
           <div class="col-md-12" >
              <b-button class="btn_stat_conseiller" @click="goToConseiller()">Simulation de prêt immobilier </b-button>
           </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import CardAvendre from "./cardAvendre.vue";
import Multiselect from "vue-multiselect";
import { mapActions } from "vuex";

export default {
  props : ['showbtn','color'],
  components: {
    CardAvendre,
    Multiselect,
  },
  name: "Ville",
  data() {
    return {
      backgroundImage: " background-image",
      images: [
        { src: "Bordeaux.png", title: "Bordeaux" },
        { src: "Paris.png", title: "Paris" },
        { src: "Monaco.png", title: "Monaco" },
        { src: "Marseille.png", title: "Marseille" },
        { src: "Toulous.png", title: "Toulouse" },
      ],
      optionsRecent: [
        { text: "Recent", value: "1", checked: false },
        /* { text: "Option 2", value: "2", checked: false },
        { text: "Option 3", value: "3", checked: false }, */
      ],
      timer: null,
      currentIndex: 0,
      ListeCardAvendre : []
    };

  },

  mounted: function () {
    this.ListeCardAvendre=[]
    this.getListeBienAvendre().then((response)=>{
       let data= response.data.data.data
        for(var i in data) {
          let element=data[i]
          let el=element[0]

          let structure={
            src:el.images_prog[0] ?? 'assets/img/card1.png',
            titre:el.type_residence,
            prix:el.prix_immo_tva_norm,
            titre_prog:el.titre_prog,
            id:el.id,
            types:"neuf",
          }

          this.ListeCardAvendre.push(structure)

        }
       console.log('ici data',data)

    });
  },

  methods: {
    ...mapActions(["getListeBienAvendre"]),
    goToAnnonce(){
      this.$router.push({ path: "/acheter" });
    },
     goToConseiller(){
      // window.location.href= "https://2l-courtage.fr/simulations/index.php";
        window.open('https://2l-courtage.fr/simulations/index.php', '_blank'); // open the twitter page on a new window 

    },
  },

  computed: {
    currentImg: function () {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    },
  },
};

</script>
<style >
.content-btn-vendre .multiselect__placeholder {
  font-weight: 400 !important;}

.content-btn-vendre .multiselect__tags {
    padding: 7px 40px 0px 8px;
    border-radius: 0.5rem;
    background: #fff !important;
  }

  .content-btn-vendre{
    display: grid;
    grid-template-columns: 50% 50%;
    width: 29%;
  }

  .content-btn-vendre .multiselect__tags {
    padding: 6px 40px 0px 8px !important;}

    .content-btn-vendre .multiselect {
      margin-top: 6px;
  }

  

  @media (max-width: 768px) {
  .multiselect .multiselect__tags {
    text-align: center !important;
  }
  }
</style>
<style scoped>
button.btn.btn_stat_conseiller.btn-secondary:hover {
    background-color: darkorange;
}
button.btn.btn_stat_conseiller.btn-secondary {
  background-color: #2da9c5;
  color: white;
  border-radius: 8px;
  border: 0px;
  margin-top: 15px;
  height: 35px;
  width: 50%;
  text-align: center;
  margin-bottom: 25px;
}
.multiselect__placeholder {
  color: #646c70 !important;
  font-family: "Poppins";
  font-weight: 400 !important;
  font-size: 14px;
  white-space: nowrap;
}
.multiselect__placeholder {
  margin-bottom: 5px !important;
}

.tile {
  position: relative;
  display: flex;
}

.img_title_ville {
  font-weight: Bold;
  background-color: #2da9c5;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  font-size: 24px;
  display: block;
  position: relative;
  bottom: 0;
  top: -10%;
  left: 0;
  right: 0; /* voir règles */
  padding: 2px;
  overflow: hidden; /* empêche le dépassement de texte */
}

.img_size {
  /* width: 250px;
  height: 185px; */
  width: 100%;
}
.img_size {
  transition: ease 1s;
}
/* SURVOLE */
.is-child:hover .img_size {
}
.is-child:hover .img_size,
.is-child:hover {
}

/* Gestion des liens */
.is-child a:link,
.is-child a:active,
.is-child a:visited,
.is-child a:hover,
.is-child a {
  text-decoration: none;
  color: #fff;
}
/*.container{
  display: flex;
  align-items: center;
}*/
.content_ville {
  padding: 10px 10px 10px 0px;
  flex: 1;
}
.sous_titre_vendre {
  /* font-family: "Poppins";
  font-size: 20px;
  color: #adaeb8;
  
  font-weight: 600; */
      font-family: "Poppins";
    font-size: 18px;
    color: #adaeb8;
    text-align: left;
    position: relative;
    font-size: 25px;
}

.sous_description{
  font-family: poppins;
  color: #ADAEB8;
  font-weight: 400;
  font-size: 14px;
}
.titre_vendre{
  font-family: "Poppins";
  font-size: 25px;
  color: #646c70;
  text-align: left;
  margin-bottom: 0px !important;
  position: relative;
  font-weight: 600;  
  text-transform: uppercase;
  font-weight: 400;
}
.second_titre_vendre{
  font-family: "Poppins";
  font-size: 25px;
  color: #646c70;
  text-align: left;
  margin-bottom: 0px !important;
  position: relative;
  font-weight: 400;
}
.titre_ville {
  font-family: "Poppins";
  font-size: 32px;
  color: #646c70;
  text-align: left;
  margin-bottom: 0px !important;
  position: relative;
  font-weight: 600;
}
.btn_all_vendre_recent {
  background-color: white;
  color: #2da9c5;
  border-radius: 8px;
  border: 0px;
  width: 74%;
  float: right;
  position: relative;
  margin-left:5px;
  margin-top: 15px;
  height: 35px;
  border:1px solid #2da9c5;
}

.btn_center .btn_all_ville:hover{
  background-color: darkorange;
}

.btn_all_vendre:hover {
  background-color: #2da9c5;
  color:white;
}
.btn_all_vendre {
  border-radius: 8px;
  width: 100%;
  float: right;
  position: relative;
  margin-left:5px;
  margin-top: 15px;
  height: 35px;
  color: #2da9c5;
  border: 0px;
  width: 91%;
  border: 1px solid #2da9c5;
  background-color: transparent;
}



.content_ville:hover {
  transform: scale(0.9);
}

.img_conseiller {
  width: 40%;
  text-align: left;
}
.conseilleul {
  display: block;
}
.content-ville {
  background-color: white;
  height: auto;
}
.content-parent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  position:relative;
  margin-bottom: 7px;
}
.content-btn-vendre{
    align-content: space-between;
    justify-content: space-between;
    align-items: start;

}
.btn_all_ville {
  background-color: #2da9c5;
  color: white;
  border-radius: 8px;
  border: 0px;
  width: 250px;
  float: right;
  position: absolute;
  right: 10px;
  margin-top: 15px;
  height: 35px;
}

.btn_all_ville:hover {
  background-color: darkorange;
}

.content-btn-vendre {
  /* margin-top: 50px; */
}

span.sous_titre_ville{
  display: flex;
  justify-content: left;
  font-size: 25px !important;
  text-transform: uppercase;
  color: #646c70 !important;
  font-weight: 600 !important;
}

button.btn-voirplus.btn_all_vendre {
  margin-right: 11px;
}

@media (max-width: 768px) {

  .content-btn-vendre {
    display: flex;
    flex-direction: column;
    width: 100%;
}
  .btn_all_vendre_recent{
    width: 100%;}
  button.btn-voirplus.btn_all_vendre {
    width: 100%;
}
  .content-parent {
    display: block;
    text-align: -webkit-center;
  }
  .container.container-ville {
    height: auto;
  }
  .tile {
    display: grid;
    grid-template-columns: 50% 50%;
    position: initial;
  }
  .titre_ville {
    text-align: center;
  }

  .content-btn-vendre{
    width: 100%;
    flex-direction: column;
    float: initial;
    position: inherit;
    margin-left: 0px;
    margin-top: 0px 
}

.bloc-title{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
}
button.btn.btn_all_ville.btn-secondary {
  width: 250px;
  position: relative;
  float: initial !important;
  right: auto !important;
  width: 100%;
}

 p.titre_vendre.col-12 {
  text-align: center;
}
.content_ville{
padding: 5px;
}
.container-vendre{
  text-align-last: center;
}
.btn_center {
  place-self: center;
  width: 100%;
}
span.sous_titre_ville{
  display: flex;
  justify-content: center;
  font-size: 25px !important;
  text-transform: uppercase;
  color: #646c70 !important;
  font-weight: 400 !important;
}
.second_titre_vendre{
text-align: center;
}

.btn_all_vendre_recent {
  margin-left:0px;
}
.btn-voirplus.btn_all_vendre{
  margin-left:0px;
}

}

@media (min-width: 769px) and (max-width: 991px) {
  .tile{
    display: grid; 
    grid-template-columns: 25% 25% 25% 25%;
  }
}

@media (max-width: 350px) {

  .tile{
    grid-template-columns: 100%;
  }
}
</style>
