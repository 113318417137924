
 import TopNav from '@/components/NavBar/TopNav.vue'; // @ is an alias to /src
 import Footer from "@/components/Footer/footer.vue";
import License from "@/components/Footer/license.vue";
 
export default {
  components: {
    TopNav,
    Footer,
    License,
  },
  data() {
    return {
      
    };
  },
  mounted() {
    
     
  },
  watch:{
    $route ( ){
         window.scrollTo(0,0);
    }
}
};
