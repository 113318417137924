<template>
  <div class="home">
    <Slider  />
    <div><VendreContainer :showbtn="showbtn" :color="color" /></div>
    <div><Ville :title="title" :button="button" /></div>
    <div><Conseiller /></div>
    <div><Stat /></div>
  </div>
</template>

<script>
import Slider from "@/components/Slider/slider.vue"; // @ is an alias to /src
import Ville from "@/components/Acceuil/Ville.vue"; // @ is an alias to /src
import Conseiller from "@/components/Acceuil/Conseiller.vue"; // @ is an alias to /src
import Stat from "@/components/Acceuil/Stat.vue"; // @ is an alias to /src
import CardAvendre from "@/components/NosAnnonceImmo/cardAvendre.vue";
import VendreContainer from "@/components/NosAnnonceImmo/aVendreContainer";

export default {
  components: {
    Ville,
    Conseiller,
    Stat,
    CardAvendre,
    VendreContainer,
   
    Slider,
  },
  data() {
    return {
      color:"#f5f8fc",
      showbtn:true,
      title:'PARTOUT EN FRANCE',
      button:"Toutes les villes"
    };
  },
  mounted() {},
};
</script>



<style>
.home {
  margin-top: 61px;
  }
  @media (max-width: 768px) {
    .home {
      margin-top: 51px !important;
      }
  }
</style>


