<template>
  <div class="content-ville">
    <div class="container content-sou-ville col-md-12 col-xs-12">


      <div class="container stat-txt">
        <div>
          <p class="titrePara col-sm-12">Conseillers immobiliers experts</p>
          <span class="sous_titre_stat_paragh col-12">
            Près de chez vous, pour un accompagnement de proximité complet et personnalisé.
          </span>

          <span class="ss_sous_titre_stat_paragh">Evaluer votre capacité d’emprunt </span>
          <div class="row col-md-12">
          <div class="txt-desc mt-3 col-md-12" >Nous vous aidons à déterminer et à optimiser votre enveloppe de financement. 
            Ainsi, vous pouvez affinez votre recherche de bien immobilier.
            Cela permettra également à nos conseillers immobiliers de vous présenter des biens en avant-première qui ne sont pas encore diffusés.
            </div>
            <div class="col-md-12" >
              <b-button class="btn_stat_conseiller" @click="goToConseiller()">Faites votre simulation de prêt </b-button>
           </div>
          </div>
        </div>
         
        </div>

      <!-- <div class="row col-md-12 col-xs-12">
        <div class="col-md-12 col-xs-12">
          <div class="content-parent-stat col-md-12 col-xs-12">
            <div class="col-md-4 col-xs-12">
              <div class="custom-icon-wrapper">
                <img src="@/assets/png/verified-user.png" />
                <p class="mini_title_stat">+ 1 000</p>
              </div>
              <p class="title-custom-icon">Conseillers immobiliers experts</p>
              <p class="sous-title-custom-icon">
                Près de chez vous, pour un accompagnement de proximité complet
                et personnalisé.
              </p>
            </div>
            <div class="col-md-4 col-xs-12">
              <div class="custom-icon-wrapper">
                <img src="@/assets/png/city.png" />
                <p class="mini_title_stat">+ 50 000</p>
              </div>
              <p class="title-custom-icon">Biens immobiliers en vente</p>
              <p class="sous-title-custom-icon">
                Partout en France, pour trouver le bien correspondant à vos
                attentes.
              </p>
            </div>
            <div class="col-md-4 col-xs-12">
              <div class="custom-icon-wrapper">
                <img src="@/assets/png/twitter-verified-badge.png" />
                <p class="mini_title_stat">+ 10 ans</p>
              </div>
              <p class="title-custom-icon">D'expérience à votre service</p>
              <p class="sous-title-custom-icon">
                À vos côtés, à chaque instant, pour faire de vos projets
                immobiliers une réussite.
              </p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>

export default {
  methods: {
    goToConseiller(){
      // window.location.href= "https://2l-courtage.fr/simulations/index.php";
        window.open('https://2l-courtage.fr/simulations/index.php', '_blank'); // open the twitter page on a new window 

    }
  },

};
</script>


<style scoped>
button.btn.btn_stat_conseiller.btn-secondary:hover {
    background-color: darkorange;
}
button.btn.btn_stat_conseiller.btn-secondary {
  background-color: #2da9c5;
  color: white;
  border-radius: 8px;
  border: 0px;
  margin-top: 15px;
  height: 35px;
  width: 50%;
  text-align: center;
  margin-bottom: 25px;
}
.container.stat-txt {
  margin-top: 25px;
  margin-bottom: 50px;
}
.txt-desc {
  font-family: "Poppins";
  font-size: 17px;
  color: #adaeb8;
  text-align: left;
}
span.ss_sous_titre_stat_paragh {
  font-family: "Poppins";
  font-size: 25px;
  text-align: left;
  margin-bottom: 0px !important;
  position: relative;
  font-weight: 400;
  display: flex;
  color: rgb(45, 169, 197);
  text-transform: uppercase;
}
span.sous_titre_stat_paragh {
  display: flex;
  justify-content: left;
  font-size: 25px !important;
  text-transform: uppercase;
  color: #646c70 !important;
  font-weight: 600 !important;
  text-align: left;
}
p.titrePara {
  font-family: "Poppins";
  font-size: 25px;
  color: #646c70;
  text-align: left;
  margin-bottom: 0px !important;
  position: relative;
  font-weight: 600;
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 25px;
}
.content-parent-stat {
  display: flex;
  justify-content: space-between;
  padding: 25px 25px 25px 25px;
}
.custom-icon-wrapper {
  display: inline-block;
  margin: 2%;
  background: #2da9c5;
  border-radius: 50%;
  border: 1px solid #2da9c5;
  padding: 50px;
  width: 220px;
  height: 210px;
  font-size: 30px;
  color: rgb(255, 255, 255);
  position: relative;
  transition: all 0.1s;
}
.custom-icon-wrapper:hover {
  background: #2da9c5;
  border-radius: 50%;
  border: 1px solid #2da9c5;
}

.custom-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.custom-icon.fa-play {
  transform: translate(-35%, -50%);
}
.titre_circle {
  font-size: 15px;
}
.title-custom-icon {
  font-size: 17px;
  font-weight: 600;
  color: #707070;
}
.sous-title-custom-icon {
  font-size: 15px;
  color: #707070;
}
.mini_title_stat {
  font-size: 20px;
  padding: 14px 14px 14px 14px;
  font-weight: bold;
}


@media (min-width: 769px) and (max-width: 991px){
  .content-sou-ville.container {
    max-width: 100%;
    text-align: -webkit-center;
}
}
@media (max-width: 768px){

  p.titrePara{
    text-align: center;
}
span.sous_titre_stat_paragh{
  text-align: center;
}
span.ss_sous_titre_stat_paragh{
  text-align: center;
}
.txt-desc{
  text-align: center;
}
  .content-parent-stat{
    display: block;
  }

}




</style>
